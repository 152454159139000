<template>
<v-ons-page id="bcaPage">
   <!--
    <v-ons-toolbar>
      <div class="left"><ons-toolbar-button @click="carouselIndex > 0 && carouselIndex--">BCA</ons-toolbar-button></div>
      <div class="center"></div>
      <div class="right"><ons-toolbar-button @click="carouselIndex < 2 && carouselIndex++">Unit List</ons-toolbar-button></div>
    </v-ons-toolbar>
    -->
    <v-ons-carousel fullscreen swipeable auto-scroll overscrollable
      :index.sync="carouselIndex"
    >
      <v-ons-carousel-item >
          <iframe seamless 
          id="view-bca-div"
          width="100%"
          height="99%"
          src="https://www.bcassessment.ca/"
          ></iframe>
          <v-ons-fab disabled position="bottom right"
            @click.native="GetBCA()">
            <v-ons-icon icon="ion-ios-download"></v-ons-icon>
          </v-ons-fab>
      </v-ons-carousel-item>
      <v-ons-carousel-item >
        <v-ons-page>
        <v-ons-card>
         <div>
      <v-ons-list-title>Please click to see the unit details</v-ons-list-title>
        <v-ons-list id="pidList">
          <v-ons-list-item v-for="item in pidList" :key="item[1]"  tappable @click="UpdateBCA2(item[4])">
            <div class="left">
          {{ item[2] }}
            </div>
          </v-ons-list-item>
        </v-ons-list>
        
         </div>
        </v-ons-card>
        <v-ons-fab  position="bottom right"
                @click.native="carouselIndex > 0 && carouselIndex--">
          <v-ons-icon icon="ion-ios-arrow-back"></v-ons-icon>
        </v-ons-fab>
        </v-ons-page>
      </v-ons-carousel-item>
    </v-ons-carousel>

  </v-ons-page>
</template>

<script>
// Import the EventBus we just created.
import EventBus from '../config/event-bus';
//for API call
import axios from 'axios'
var aPID=null;
var aplannumber=null;
const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)
export default {
  data () {
    return {
      hasPID:false,
      hasplannumber:false,
      pidList:[],
      showList:false,
      carouselIndex: 0,
     };
  },
  created(){
        EventBus.$on('get_pid',(data)=>{
            if (data !==null){
            aPID=data;
            console.log("Get property event in bca: " + aPID);
            this.hasPID=true
            } else {aPID=null;this.hasPID=false}
        }),
          EventBus.$on('get_plannumber',(data)=>{
            if (data !==null){
            aplannumber=data;
            console.log("Get property event in bca: " + aplannumber);
            this.hasPID=true
            this.hasplannumber=true
            } else {aplannumber=null;this.hasplannumber=false}
        })
    },
    computed: {
        isDisabled() {
            return (!this.hasPID)
        }
    },

  methods: {
    PullDetails(){
      this.carouselIndex < 1 && this.carouselIndex++
    },
    GetBCA(){
      if (aPID!==null) {
        this.GetBCAByPID()
      }
      else if (aplannumber!==null) {
        this.PullDetails()
        this.GetBCAByPlan()
      }
    },
    GetBCAByPlan(){
    console.log("call GetBCAByPlan in BCA");
    if (aplannumber !== null) {
    
    var tokenURL = "https://www.bcassessment.ca/Property/Search/GetByPlan?plan=" +aplannumber;
    let that=this
    axios.get(proxyurl1 +tokenURL)
        .then(function(response) {
           console.log(response);
           if (response.data=="too_many_results"){
             that.pidList=null
             that.$ons.notification.alert("Too many units found!")
             that.carouselIndex > 0 && that.carouselIndex--
           } else 
           {
           that.pidList=response.data.aaData
           console.log('pidList',that.pidList)
           console.log('Total units',response.data.sEcho)
           }
           //that.showList=true
        })

    } else {alert( "This parcel has no Plan Number provided for BC Assessment Search!")}},
    GetBCAByPID(){
    // iPID in numberic format without '-'
    console.log("call GetBCAByPID in BCA");
    if (aPID !== null) {
    var iPID=aPID.replace(/[^a-zA-Z0-9]/g, '');
    var tokenURL = "https://www1.bcassessment.ca/Property/Search/GetByPid/" +iPID;
    axios.get(proxyurl1 + tokenURL)
        .then(function(response) {
            var softToken = response.data.aaData[0][4];
            console.log(response);
            sleep(1000);
            console.log("softToken :" + softToken);
            UpdateBCA(softToken);

        })

    } else {alert( "This parcel has no PID provided for BC Assessment Search!")}

    function sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
        if ((new Date().getTime() - start) > milliseconds){
        break;
        }
    }
    } // end of sleep
    function   UpdateBCA(softToken) {
    console.log("softtoken",softToken)
    var tabpage = document.getElementById("view-bca-div");
    if (softToken) {
    tabpage.setAttribute("src", 'https://www.bcassessment.ca//Property/Info/' + softToken);
    }
    } // end of UpdateBCA
    },  // end of GetBCAByPID
    UpdateBCA2(softToken2) {
    console.log("softtoken2",softToken2)
    var tabpage2 = document.getElementById("view-bca-div");
    if (softToken2) {
    tabpage2.setAttribute("src", 'https://www.bcassessment.ca//Property/Info/' + softToken2);
    this.carouselIndex > 0 && this.carouselIndex--
    }
    } // end of UpdateBCA2

  } //end of methods
}
</script>
