import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// DHO account , Terratonepull-core production environment

const configOptions = {
  apiKey: "AIzaSyDFRMs4-ZvsMCqBnPGlLMUq4TyxzpHNEiQ",
  authDomain: "terratonepull-core.firebaseapp.com",
  projectId: "terratonepull-core",
  storageBucket: "terratonepull-core.appspot.com",
  messagingSenderId: "183264789208",
  appId: "1:183264789208:web:06ec929f99fae5250ccdad",
  measurementId: "G-S58RH33SQT"
};

// project nj for development
/*
const configOptions = {
  apiKey: "AIzaSyBUKCQmgk-cBgT4cIYev3-rLuZdFee0AT8",
  authDomain: "tp-nj-4e83c.firebaseapp.com",
  databaseURL: "https://tp-nj-4e83c.firebaseio.com",
  projectId: "tp-nj-4e83c",
  storageBucket: "tp-nj-4e83c.appspot.com",
  messagingSenderId: "6772780528",
  appId: "1:6772780528:web:e9220c47279206f2b68135",
  measurementId: "G-EFQ7KNJ62J"
};
*/
// The following fields are REQUIRED:
//  - Project ID
//  - App ID
//  - API Key
const secondaryAppConfig = {
  apiKey: "AIzaSyDODoKxSk3wGr9_iZmc71CHFiJPDJlezwA",
  authDomain: "terratonepull.firebaseapp.com",
  databaseURL: "https://terratonepull.firebaseio.com",
  projectId: "terratonepull",
  storageBucket: "terratonepull.appspot.com",
  appId: "1:850768789997:web:42cc8576deb634e9e5a7e4",
};



// project terratonepull for production
/*
const configOptions = {

apiKey: "AIzaSyDODoKxSk3wGr9_iZmc71CHFiJPDJlezwA",
authDomain: "terratonepull.firebaseapp.com",
databaseURL: "https://terratonepull.firebaseio.com",
projectId: "terratonepull",
storageBucket: "terratonepull.appspot.com",
messagingSenderId: "850768789997",
appId: "1:850768789997:web:42cc8576deb634e9e5a7e4",
measurementId: "G-FX8JD7SSC1"
};
*/

firebase.initializeApp(configOptions);
var firebase2=firebase.initializeApp(secondaryAppConfig,"secondary");

const storage2=firebase2.storage()
const db2=firebase2.firestore()

// firebase utils
const storage=firebase.storage()
const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser
const fbemail_ID=firebase.auth.EmailAuthProvider.PROVIDER_ID
// firebase collections
const usersCollection = db.collection('users')

// team mortgage
// team doc id='Tyo1qym2EzJgOgKB6g0I'
const postsCollection= db.collection('teams').doc('Tyo1qym2EzJgOgKB6g0I').collection('posts')
const commentsCollection= db.collection('teams').doc('Tyo1qym2EzJgOgKB6g0I').collection('comments')
const likesCollection= db.collection('teams').doc('Tyo1qym2EzJgOgKB6g0I').collection('likes')
const commentlikesCollection= db.collection('teams').doc('Tyo1qym2EzJgOgKB6g0I').collection('commentlikes')

/*
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')
const commentlikesCollection = db.collection('commentlikes')
*/
const corpsearchRef=db2.collection('corpsearch')
const titlesearchRef=db2.collection('titlesearch')
const mergedlandsCollection = db.collection('mergedlands')


export default {
  //auth:firebase.auth(),
  storage,
  db,
  auth,
  currentUser,
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection,
  fbemail_ID,
  corpsearchRef,
  titlesearchRef,
  mergedlandsCollection,
  db2,
  storage2,
  firebase2,
  commentlikesCollection
}
