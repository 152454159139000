import TileLayer from "ol/layer/Tile"
import OSM from "ol/source/OSM"
import XYZ from 'ol/source/XYZ'
import TileWMS from 'ol/source/TileWMS'
import KML from 'ol/format/KML'
import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector';
import {Circle, Fill, Stroke, Style, Text,Icon} from 'ol/style';
import fb from "../firebase"
import { store } from "../store";
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import {createXYZ} from 'ol/tilegrid';
import MVT from 'ol/format/MVT';
import Feature from "ol/Feature";
import {bbox as bboxStrategy} from 'ol/loadingstrategy';
import ClusterSource from "ol/source/Cluster";


var mortgagevlayer=function(){
  var  vectorlayer = new VectorLayer({
    visible:false,
    name:'mortgagevlayer',
    style:customStyleFunction,
    source: new VectorSource({
      url: function (extent) {
        //mortgage20232024M2017Sep2022S
        return (
          'https://geoterratone.com/geoserver/wfs?service=WFS&' +
          'version=1.1.0&request=GetFeature&typename=Terradots:mortgageMay22Feb23M2017Sep2023S&' +
          'outputFormat=application/json&srsname=EPSG:3857&' +
          'bbox=' +
          extent.join(',') +
          ',EPSG:3857'
        );
      },
      strategy: bboxStrategy,
      format: new GeoJSON(),
    }),

  })
  return vectorlayer
};



var style = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.6)',
  }),
  stroke: new Stroke({
    color: '#319FD3',
    width: 1,
  }),
  text: new Text({
    font: '12px Calibri,sans-serif',
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 3,
    }),
  }),
});

var iconscale=0.08
var APTiconStyle = new Style({
  image: new Icon({
    //anchor: [0.5, 46],
    //anchorXUnits: 'fraction',
    //anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/APT.png',//'img/home-map-marker.png',
  }),
});
var ICIiconStyle = new Style({
  image: new Icon({
   // anchor: [0.5, 46],
   // anchorXUnits: 'fraction',
   // anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/ICI.png',//'img/home-map-marker.png',
  }),
});
var OFFiconStyle = new Style({
  image: new Icon({
    //anchor: [0.5, 46],
   // anchorXUnits: 'fraction',
   // anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/OFF.png',//'img/home-map-marker.png',
  }),
});
var RLNiconStyle = new Style({
  image: new Icon({
    //anchor: [0.5, 46],
    //anchorXUnits: 'fraction',
    //anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/RLN.png',//'img/home-map-marker.png',
  }),
});
var HOTiconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 46],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/HOT.png',//'img/home-map-marker.png',
  }),
});
var INDiconStyle = new Style({
  image: new Icon({
   // anchor: [0.5, 46],
   // anchorXUnits: 'fraction',
   // anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/IND.png',//'img/home-map-marker.png',
  }),
});
var RETiconStyle = new Style({
  image: new Icon({
    //anchor: [0.5, 46],
   // anchorXUnits: 'fraction',
   // anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img/RET.png',//'img/home-map-marker.png',
  }),
});
var NOTYPEiconStyle = new Style({
  image: new Icon({
   // anchor: [0.5, 46],
   // anchorXUnits: 'fraction',
   // anchorYUnits: 'pixels',
    scale:iconscale,
    src: 'img//NOTYPE.png',//'img/home-map-marker.png',
  }),
});

var customStyleFunction=function(feature,resolution){
  //console.log("feature:",feature)
  //console.log('feature:',feature.get('InvNum').split("-")[1])
  var iconStyle=null
  //if (Date.parse(feature.get('MatDate'))>=Date.parse('2023/01/01')){
    let LTV=(feature.get('MAmt')/feature.get('Price')).toFixed(2)
    //console.log("LTV:",LTV)
    iconscale=(typeof((feature.get('MAmt')/feature.get('Price')))=="undefined"| (feature.get('MAmt')/feature.get('Price'))> 1) ? 0.09:0.09*(feature.get('MAmt')/feature.get('Price'))
    //console.log("iconscale:",iconscale)
    
    
    
    
    
    HOTiconStyle.getImage().setScale(iconscale);
    
  switch  (feature.get('InvNum').split("-")[1]) {
  case 'RLN':
    RLNiconStyle.getImage().setScale(iconscale);
    iconStyle=RLNiconStyle;
    break;
  case 'APT':
    APTiconStyle.getImage().setScale(iconscale);
    iconStyle=APTiconStyle;
    break;
  case 'IND':
    INDiconStyle.getImage().setScale(iconscale);
    iconStyle=INDiconStyle;
    break;
  case 'OFF':
    OFFiconStyle.getImage().setScale(iconscale);
    iconStyle=OFFiconStyle;
    break;
  case 'RET':
    RETiconStyle.getImage().setScale(iconscale);
    iconStyle=RETiconStyle;
    break;
  case 'ICI':
    ICIiconStyle.getImage().setScale(iconscale);
    iconStyle=ICIiconStyle;
    break;
  case 'HOT':
    iconStyle=HOTiconStyle;
    break;
  default:
    NOTYPEiconStyle.getImage().setScale(iconscale);
    iconStyle=NOTYPEiconStyle;
  };
//}
  /*
  switch  (feature.get('InvNum').split("-")[2]) {
    case '2017':
      iconStyle=RLNiconStyle;
      break;
    case '2018':
      iconStyle=APTiconStyle;
      break;
    case '2019':
      iconStyle=INDiconStyle;
      break;
    case '2020':
      iconStyle=OFFiconStyle;
      break;
    case '2021':
      iconStyle=RETiconStyle;
      break;
    case '2022':
      iconStyle=ICIiconStyle;
      break;
    default:
      iconStyle=NOTYPEiconStyle;
    }
*/
  
  return iconStyle

/*
  return new Style({
    image:new Circle({
      radius:7,
      fill:new Fill({
        color:strokecolor,
        stroke:new Stroke({
          color:strokecolor
        })
      })
    })
  }) */
}
var mortgagemap=function(){
  var vectortilelayer=new VectorTileLayer({
    name:'Mortgag Layer',
    style:customStyleFunction,
    visible:true,
    
    maxZoom:20,
    source: new VectorTileSource({
      tilePixelRatio: 1, // oversampling when > 1
      tileGrid: createXYZ({maxZoom: 19}),
      format: new MVT(   {featureClass: Feature} ), 
      url: 'https://geoterratone.com:/geoserver/Terradots/gwc/service/tms/1.0.0/' + 'mortgage2017sept2022' +
          '@EPSG%3A'+'900913'+'@pbf/{z}/{x}/{-y}.pbf'
    })
  })
  return vectortilelayer
}
var indmap=function(){
  var   indlayer = new VectorLayer({
    visible:true,
    source: new VectorSource({
      url: 'data/MortgageLayer20172022.kml',
      format: new KML(),
    }),
  })
  return indlayer
};

var streetmap=function(){
    var mapbaselayer=null;
            mapbaselayer=new TileLayer({
                source: new OSM(),
                type:'base',
                baseLayer:true
            })
    return mapbaselayer;
};
var googlemap=function(){
  var mapbaselayer=null;
          mapbaselayer=new TileLayer({
              source: new XYZ({
                attributions: '© <a href="https://cloud.google.com/maps-platform/terms/" target="_blank">Powered By Google. </a>',
                //attributionsCollapsible: false,
                url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
              }),
              type:'base',
              baseLayer:true
          })
  return mapbaselayer;
};
var satellitemap=function(){
    var additonallayers=null;
            additonallayers=new TileLayer({
                title:'Satellite view',
                name:'GSatellite',
                visible:false,
              source: new XYZ({
                attributions: ['Powered by Esri',
                               'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                maxZoom: 23
              })
            });
    return additonallayers;
}
var bcparcelmap=function(){
    var parcelLayer = new TileLayer({
        title: 'Parcel lines',
        displayInLayerPopup:'0',
        opacity:0.7,
        minZoom:12,
        bgcolor:'0x80BDE3',
        /*
        // Perry extent
        extent:[-13708573.09907316,
          6317899.993763422,
          -13706121.72827147,
          6319568.366776173
        ],
        */
        // Richmond area west of No2 ,  Request by David for App on 9 Mar 2021
        //extent:[-13722433.185728591,
        //          6294292.953649552,
        //        -13709974.340310227,
        //          6305043.228675206],
        source: new TileWMS({
      
          url:
            "https://openmaps.gov.bc.ca/geo/pub/WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW/ows",
          crossOrigin: null,
          params: {
            LAYERS: "WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW",
          }
        })
        
      });
    return parcelLayer;
}
var bookmarksmap=function(){
   var style = [
    new Style({
      stroke: new Stroke({
        color: "black",
        width: 4,
      }),
      fill: new Fill({
        color: "green",
      }),
    }),
  ];
  var bookmarkLayer=new VectorLayer({
    title:'Bookmarks',
    name:'Bookmarks',
    source: new VectorSource(),
    style : style ,
    visible:false
  
  })
  
  return bookmarkLayer
}
var mapconfig={
    x:-123.1175904,     
    y:49.28764,
    zoom:9,          
    streetmap:streetmap(),
    googlemap:googlemap(),
    satellitemap:satellitemap(),
    bcparcelmap:bcparcelmap(),
    bookmarksmap:bookmarksmap(),
    //indmap:indmap(),
    //mortgagemap:mortgagemap(),
    mortgagevlayer:mortgagevlayer(),

    

};
export default mapconfig



